<template>
  <!--begin::Layout-->
  <div v-if="emailTemplate" class="d-flex flex-column flex-lg-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
      <!--begin::Card-->
      <div class="card card-flush pt-3 mb-5 mb-xl-10">
        <!--begin::Card body-->
        <div class="card-body pt-3">
          <h2 class="fw-bolder me-6">
            Email Template
            <small v-if="!canEdit" class="d-block text-muted"
              >This template is managed by Neoris. You cannot edit it.</small
            >
          </h2>

          <div class="mb-4">
            <label class="d-block">Title</label>
            <input
              v-model="emailTemplate.title"
              :disabled="!canEdit"
              type="text"
              class="form-control"
              placeholder="Title"
            />
          </div>

          <!--begin::Label-->
          <div
            v-if="canEdit && !isClinicTemplate"
            class="col-lg-8 d-flex align-items-center mb-10"
          >
            <div class="form-check form-check-solid form-switch fv-row">
              <input
                class="form-check-input w-45px h-30px"
                type="checkbox"
                id="display-website"
                v-model="emailTemplate.sharedWithClinics"
              />
              <label
                class="form-check-label text-muted mt-1 ms-3"
                for="display-website"
                >Allows Clinics to see and use this email template.</label
              >
            </div>
          </div>
          <!--begin::Label-->

          <h2 class="fw-bolder me-6">
            Email content ({{
              emailTemplate.templates[selectedTemplateIdx].language
            }})
          </h2>

          <!--begin::Patient Section-->
          <div class="mb-10">
            <div class="mb-4">
              <label class="d-block">Subject</label>
              <input
                v-model="emailTemplate.templates[selectedTemplateIdx].subject"
                :disabled="!canEdit"
                type="text"
                class="form-control"
                placeholder="Subject"
              />
            </div>

            <!--begin::Message-->
            <div id="kt_inbox_form_editor" class="border-0 h-auto mt-4">
              <label class="d-block">Content</label>
              <editor
                v-model="emailTemplate.templates[selectedTemplateIdx].content"
                :disabled="!canEdit"
                api-key="8igvtp1e529xdwdw7evs0t7wu1b111ec7qriiyafxn0arzpp"
                placeholder="Your message..."
                :init="{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media',
                    'paste code help wordcount table',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic |         alignleft aligncenter alignright |         bullist numlist outdent indent | help',
                }"
              >
              </editor>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Content-->

    <!--begin::Sidebar-->
    <div
      class="
        flex-column flex-lg-row-auto
        w-lg-250px w-xl-300px
        mb-10
        order-1 order-lg-2
      "
    >
      <!--begin::Card-->
      <div
        class="card card-flush mb-4"
        data-kt-sticky="true"
        data-kt-sticky-name="subscription-summary"
        data-kt-sticky-offset="{default: false, lg: '200px'}"
        data-kt-sticky-width="{lg: '250px', xl: '300px'}"
        data-kt-sticky-left="auto"
        data-kt-sticky-top="150px"
        data-kt-sticky-animation="false"
        data-kt-sticky-zindex="95"
      >
        <!--begin::Card header-->
        <div class="card-header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>Current language edition</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0 fs-6">
          <!--begin::Section-->
          <div class="mb-4">
            <select
              class="btn btn-primary fs-bold me-4"
              data-kt-inbox-form="send"
              v-model="selectedTemplateIdx"
            >
              <option
                v-for="(template, idx) of emailTemplate.templates"
                :key="template.language"
                :value="idx"
              >
                {{ template.language }}
              </option>
            </select>

            <a
              href="#"
              v-if="canEdit && emailTemplate.templates.length > 1"
              @click.prevent="deleteTemplate(selectedTemplateIdx)"
              style="z-index: 10"
            >
              <i class="fas fa-trash text-danger" />
            </a>
          </div>
          <!--end::Attachments-->
        </div>
        <!--end::Section-->
      </div>

      <!--begin::Card-->
      <div
        class="card card-flush mb-4"
        data-kt-sticky="true"
        data-kt-sticky-name="subscription-summary"
        data-kt-sticky-offset="{default: false, lg: '200px'}"
        data-kt-sticky-width="{lg: '250px', xl: '300px'}"
        data-kt-sticky-left="auto"
        data-kt-sticky-top="150px"
        data-kt-sticky-animation="false"
        data-kt-sticky-zindex="95"
      >
        <!--begin::Card header-->
        <div class="card-header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>
              Attachments ({{
                emailTemplate.templates[selectedTemplateIdx].language
              }})
            </h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0 fs-6">
          <!--begin::Section-->
          <div class="mb-4">
            <!--begin::Attachments-->
            <div
              class="dropzone dropzone-queue"
              id="kt_inbox_reply_attachments"
              data-kt-inbox-form="dropzone"
            >
              <div class="dropzone-items">
                <div
                  class="dropzone-item"
                  v-for="(attachment, idx) in emailTemplate.templates[
                    selectedTemplateIdx
                  ].attachments"
                  :key="idx"
                >
                  <!--begin::Dropzone filename-->
                  <div class="dropzone-file">
                    <div
                      class="dropzone-filename"
                      title="some_image_file_name.jpg"
                    >
                      <span data-dz-name="">{{ attachment.filename }}</span>
                      <strong>
                        (<span data-dz-size="">{{ attachment.size }}</span
                        >)</strong
                      >
                    </div>
                    <div class="dropzone-error" data-dz-errormessage=""></div>
                  </div>
                  <!--end::Dropzone filename-->
                  <!--begin::Dropzone toolbar-->
                  <div
                    v-if="canEdit"
                    @click="deleteAttachment(attachment)"
                    class="dropzone-toolbar"
                  >
                    <span class="dropzone-delete" data-dz-remove="">
                      <i class="bi bi-x fs-1" />
                    </span>
                  </div>
                  <!--end::Dropzone toolbar-->
                </div>
              </div>
            </div>
            <!--end::Attachments-->

            <!--begin::Upload attachment-->
            <div
              v-if="canEdit"
              class="
                btn btn-icon btn-light-info
                me-2
                my-2
                attachment-input-wrapper
              "
              title="Add an attachment"
            >
              <i class="bi bi-paperclip fs-4" />
              <input
                type="file"
                multiple
                :name="uploadFieldName"
                :disabled="isSaving"
                @change="
                  filesChange($event.target.name, $event.target.files);
                  fileCount = $event.target.files.length;
                "
                accept="image/*,application/pdf"
                class="input-file"
              />
            </div>
            <!--end::Upload attachment-->
          </div>
          <!--end::Section-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->

      <!--begin::Card-->
      <div
        v-if="canEdit"
        class="card card-flush mb-0"
        data-kt-sticky="true"
        data-kt-sticky-name="subscription-summary"
        data-kt-sticky-offset="{default: false, lg: '200px'}"
        data-kt-sticky-width="{lg: '250px', xl: '300px'}"
        data-kt-sticky-left="auto"
        data-kt-sticky-top="150px"
        data-kt-sticky-animation="false"
        data-kt-sticky-zindex="95"
      >
        <!--begin::Card header-->
        <div class="card-header">
          <div class="card-title">
            <h2>Actions</h2>
          </div>
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0 fs-6">
          <!--begin::Section-->
          <div class="mb-8 d-flex">
            <!--begin::Select Template Language-->
            <select
              class="btn btn-primary fs-bold me-4"
              data-kt-inbox-form="send"
              v-model="addLanguageSelector"
            >
              <option value="" selected disabled></option>
              <option
                v-if="!emailTemplate.templates.find((e) => e.language == 'EN')"
              >
                EN
              </option>
              <option
                v-if="!emailTemplate.templates.find((e) => e.language == 'FR')"
              >
                FR
              </option>
              <option
                v-if="!emailTemplate.templates.find((e) => e.language == 'ES')"
              >
                ES
              </option>
              <option
                v-if="!emailTemplate.templates.find((e) => e.language == 'IT')"
              >
                IT
              </option>
              <option
                v-if="!emailTemplate.templates.find((e) => e.language == 'DE')"
              >
                DE
              </option>
            </select>
            <!--end::Select Template Language-->

            <button
              @click.prevent="addLanguage(addLanguageSelector)"
              class="d-block btn btn-secondary"
            >
              Add language
            </button>
          </div>
          <!--end::Attachments-->

          <!--begin::Actions-->
          <div class="mb-0 d-grid">
            <!--begin::Save-->
            <div
              v-if="savingEmail"
              class="btn btn-primary fs-bold disabled"
              disabled
            >
              <span class="disabled">
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                />
                Saving...
              </span>
            </div>
            <div
              v-else
              class="btn btn-primary fs-bold"
              data-kt-inbox-form="send"
              @click="saveEmailTemplate"
            >
              <span class="indicator-label">Save</span>
            </div>
            <!--end::Save-->
          </div>
          <!--end::Actions-->
          
            <!--begin::Seperator-->
            <div class="separator separator-dashed my-4"></div>
            <!--end::Seperator-->

            <!--begin::Actions-->
            <div class="mb-0 d-grid">
                <a @click="deleteEmailTemplate" class="btn btn-light-danger">Delete template</a>
            </div>
            <!--end::Actions-->
        </div>
        <!--end::Section-->
      </div>
    </div>
    <!--end::Sidebar-->
  </div>
  <!--end::Layout-->
</template>

<script>
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Editor from "@tinymce/tinymce-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";

export default {
  name: "EmailTemplate",
  components: {
    Editor,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      addLanguageSelector: "",
      emailTemplate: null,
      selectedTemplateIdx: 0,
      savingEmail: false,

      // Attachments upload
      uploadFieldName: "attachments",
      isSaving: false,
    };
  },
  computed: {
    canEdit() {
      const user = JwtService.getUser();
      return (
        user.superAdmin ||
        user.clinic.id == this.emailTemplate.clinic ||
        !this.emailTemplate.id
      );
    },
    isClinicTemplate() {
      return !JwtService.isSuperAdmin();
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("View Email Template", [
      { label: "Email Templates", to: "/email-templates" },
    ]);

    if (this.$route.params.id) {
      ApiService.get("email-templates", this.$route.params.id).then(
        ({ data }) => {
          console.log(data);
          this.emailTemplate = data;
        }
      );
    } else {
      this.emailTemplate = {
        title: "",
        templates: [
          {
            language: "EN",
            subject: "",
            content: "",
            attachments: [],
          },
        ],
      };
    }
  },
  methods: {
    addLanguage(languageCode) {
      if (languageCode) {
        this.emailTemplate.templates.push({
          language: languageCode,
          subject: "",
          content: "",
          attachments: [],
        });
        this.selectedTemplateIdx = this.emailTemplate.templates.length - 1;
      }
    },
    deleteTemplate(templateIdx) {
      if (this.emailTemplate.templates.length == 1) return;
      if (templateIdx > this.emailTemplate.templates.length - 1) return;

      this.selectedTemplateIdx = 0;
      this.emailTemplate.templates.splice(templateIdx, 1);
    },
    deleteAttachment(attachment) {
      let attachments =
        this.emailTemplate.templates[this.selectedTemplateIdx].attachments;
      this.emailTemplate.templates[this.selectedTemplateIdx].attachments =
        attachments.filter((e) => e.filename !== attachment.filename);
    },
    humanReadableSize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
      );
    },
    async filesChange(fieldName, fileList) {
      this.isSaving = true;

      if (!fileList.length) return;

      for (const file of fileList) {
        await new Promise((resolve) => {
          const fr = new FileReader();
          fr.addEventListener("load", (e) => {
            this.emailTemplate.templates[
              this.selectedTemplateIdx
            ].attachments.push({
              filename: file.name,
              size: this.humanReadableSize(file.size),
              path: e.target.result,
            });
            resolve(e.target.result);
          });
          fr.readAsDataURL(file);
        });
      }

      this.isSaving = false;
    },
    saveEmailTemplate() {
      this.savingEmail = true;

      // Checks if all languages have content
      if(this.emailTemplate.templates.some(template => !template.subject.length || !template.content.length)) {
        this.toast.error("A language is empty. Please add content or delete that language.");
        this.savingEmail = false;
        return;
      }

      if (this.emailTemplate.id) {
        const config = {
          responseToast: {
            text: "Template updated successfully.",
          },
        };
        ApiService.update(
          "email-templates",
          this.emailTemplate.id,
          this.emailTemplate,
          config
        )
          .then(({ data }) => {
            this.emailTemplate = data;
            this.savingEmail = false;
          })
          .catch((err) => {
            this.savingEmail = false;
          });
      } else {
        const config = {
          responseToast: {
            text: "Template created successfully.",
          },
        };
        ApiService.post("email-templates", this.emailTemplate, config)
          .then(({ data }) => {
            this.emailTemplate = data;
            this.savingEmail = false;
            this.$router.replace({
              name: "email-template",
              params: { id: this.emailTemplate.id },
            });
          })
          .catch((err) => {
            this.savingEmail = false;
          });
      }
    },
    deleteEmailTemplate() {
        Swal.fire({
            text: `Delete this template?`,
            icon: "warning",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Delete",
            customClass: {
            cancelButton: "btn btn-secondary",
            confirmButton: "btn btn-primary",
            },
        }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            ApiService.delete(`email-templates/${this.emailTemplate.id}`).then(() => {
                Swal.fire({ text: `Template deleted`, icon: "success" });
                this.$router.push("/email-templates");
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-input-wrapper {
  position: relative;
  cursor: pointer;

  input[type="file"] {
    cursor: inherit;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: right;
  }
}

.dropzone.dropzone-queue .dropzone-item {
  word-break: break-all;
}
</style>
